/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { includes } from 'lodash';
import { buildFooterLinks } from './Footer.helper';

import FooterProducts from '../FooterProducts/FooterProducts';

import Style from './Footer.scss';
import { classNameForGA, dataActionForGA } from '../../helpers/dataForGA';

const Footer = ({ navigation, retailers, products }) => {
    const currentYear = new Date().getFullYear();

    const footerLinks = buildFooterLinks(navigation);

    return (
        <div className="full-width-mobile footer">
            <div className="container">
                <ul className="footer-list">
                    {footerLinks.map((link) => {
                        const className = includes(link.title, 'AdChoices') ? 'ad-choices' : '';
                        const target = link.newWin ? '_blank' : '_self';
                        const rel = link.newWin ? 'noopener noreferrer' : '';

                        return (
                            <li key={link.title}>
                                <a
                                    href={link.url}
                                    className={`${className} ${classNameForGA(link.url)}`}
                                    {...dataActionForGA(link.url)}
                                    target={target}
                                    rel={rel}
                                >
                                    {link.title}
                                </a>
                            </li>
                        );
                    })}
                    <li className="copy">&copy; {currentYear} Procter &amp; Gamble</li>
                </ul>
            </div>
            <style jsx>{Style}</style>
        </div>
    );
};

Footer.propTypes = {
    navigation: PropTypes.object,
    products: PropTypes.object,
    retailers: PropTypes.object,
};

export default Footer;
